import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const BsFormInfoField = (props) => {
    const {t} = useTranslation();

    let valueDecoration = 'bs-form__info-value';
    switch (props.valueDecoration) {
        case 'capitalize':
            valueDecoration += ' bs-form__info-value-capitalize'
            break;
    }

    if (props.onClick) {
        valueDecoration += ' bs-form__info-value-active'
    }

    return (
        <div className="bs-form__info">
            <div className="bs-form__info-label">
                {t(props.label)}
            </div>
            <div className={valueDecoration} onClick={props.onClick}>
                {(props.translate ? t(props.value) : props.value)}
            </div>
        </div>
    )
}

BsFormInfoField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    translate: PropTypes.bool,
    valueDecoration: PropTypes.string
};

export default BsFormInfoField;