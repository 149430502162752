const LOCATION_BICYCLES_INFO_FORM = {
    columns: [
        {
            label: 'bicycles.number_header',
            field: 'number',
            ordering: true
        },
        {
            label: 'bicycles.model_header',
            field: 'model',
            ordering: true
        },
        {
            label: 'bicycles.status_header',
            field: 'status',
            ordering: true,
            translate: true,
            getValue(raw) {
                return 'bike_status.' + raw.status;
            }
        },
        {
            label: 'bicycles.serial_number_header',
            field: 'serialNumber',
            ordering: true
        },
        {
            label: 'bicycles.lock_header',
            field: 'lockMacAddress',
            ordering: true
        },
        {
            label: 'bicycles.power_level_header',
            field: 'powerLevel',
            ordering: true,
            getValue(raw) {
                return raw.powerLevel ? (raw.powerLevel + "%") : 'None';
            }
        },
        {
            label: 'bicycles.last_scan_header',
            field: 'lastScan',
            ordering: true,
            getValue(raw) {
                return raw.lastScan ? raw.lastScan : '-'
            }
        }
    ]
}

export default LOCATION_BICYCLES_INFO_FORM;
