export const TABLE_ACTIVE_RENTALS = {
    columns: [
        {
            label: 'rentals.bicycle_header',
            field: 'bike.number',
            getValue: (raw) => {
                return "# " + raw.bike.number
            },
            ordering: true
        },
        {
            label: 'rentals.full_name_header',
            field: 'client.firstName,client.lastName',
            getValue: (raw) => {
                return raw.client.firstName + ' ' + raw.client.lastName
            },
            ordering: true
        },
        {
            label: 'rentals.start_location_header',
            field: 'startLocation.label',
            ordering: true,
            getValue(raw) {
                return raw.startLocation.label;
            }
        },
        {
            label: 'rentals.start_time_header',
            field: 'startTime',
            getValue: (raw) => {
                return raw.startTime
            },
            ordering: true
        }
    ]
}
