import React from "react";

export const TABLE_RENTALS_HISTORY = {
  columns: [
    {
      label: 'rentals.bicycle_header',
      field: 'bike.number',
      getValue: (raw) => {
        return "# " + raw.bike.number
      },
      ordering: true
    },
    {
      label: 'rentals.full_name_header',
      field: 'client.firstName,client.lastName',
      getValue: (raw) => {
        return raw.client.firstName + ' ' + raw.client.lastName
      },
      ordering: true
    },
    {
      label: 'rentals.start_location_header',
      field: 'startLocation.label',
      ordering: true,
      getValue(raw) {
        return raw.startLocation.label;
      }
    },
    {
      label: 'rentals.end_location_header',
      field: 'endLocation.label',
      ordering: true,
      getValue(raw) {
        return raw.endLocation ? raw.endLocation.label : '-';
      }
    },
    {
      label: 'rentals.start_time_header',
      field: 'startTime',
      getValue: (raw) => {
        return raw.startTime ? raw.startTime : '-'
      },
      ordering: true
    },
    {
      label: 'rentals.end_time_header',
      field: 'endTime',
      getValue: (raw) => {
        return raw.endTime ? raw.endTime : '-'
      },
      getStyle(raw) {
        return raw.hasReturnProblem ? {color: '#f44336'} : {};
      },
      ordering: true
    },
    {
      label: 'rentals.has_return_problem_header',
      field: 'hasReturnProblem',
      getStyle(raw) {
        return raw.hasReturnProblem ? {fill: '#f44336'} : {};
      },
      ordering: true
    }
  ]
}
