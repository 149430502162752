const TIME_ZONES = [
  {value: "Etc/GMT+12", label: "(UTC-12:00) International Date Line West"},
  {value: "Etc/GMT+11", label: "(UTC-11:00) Coordinated Universal Time-11"},
  {value: "Etc/GMT+10", label: "(UTC-10:00) Hawaii"},
  {value: "America/Anchorage", label: "(UTC-09:00) Alaska"},
  {value: "America/Santa_Isabel", label: "(UTC-08:00) Baja California"},
  {value: "America/Dawson", label: "(UTC-07:00) Pacific Time (US & Canada)"},
  {value: "America/Phoenix", label: "(UTC-07:00) Arizona"},
  {value: "America/Chihuahua", label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan"},
  {value: "America/Denver", label: "(UTC-07:00) Mountain Time (US & Canada)"},
  {value: "America/Guatemala", label: "(UTC-06:00) Central America"},
  {value: "America/Chicago", label: "(UTC-06:00) Central Time (US & Canada)"},
  {value: "America/Mexico_City", label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey"},
  {value: "America/Regina", label: "(UTC-06:00) Saskatchewan"},
  {value: "America/Bogota", label: "(UTC-05:00) Bogota, Lima, Quito"},
  {value: "America/New_York", label: "(UTC-05:00) Eastern Time (US & Canada)"},
  {value: "America/Indiana/Indianapolis", label: "(UTC-05:00) Indiana (East)"},
  {value: "America/Caracas", label: "(UTC-04:30) Caracas"},
  {value: "America/Asuncion", label: "(UTC-04:00) Asuncion"},
  {value: "America/Halifax", label: "(UTC-04:00) Atlantic Time (Canada)"},
  {value: "America/Cuiaba", label: "(UTC-04:00) Cuiaba"},
  {value: "America/La_Paz", label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"},
  {value: "America/Santiago", label: "(UTC-04:00) Santiago"},
  {value: "America/St_Johns", label: "(UTC-03:30) Newfoundland"},
  {value: "America/Sao_Paulo", label: "(UTC-03:00) Brasilia"},
  {value: "America/Argentina/Buenos_Aires", label: "(UTC-03:00) Buenos Aires"},
  {value: "America/Cayenne", label: "(UTC-03:00) Cayenne, Fortaleza"},
  {value: "America/Godthab", label: "(UTC-03:00) Greenland"},
  {value: "America/Montevideo", label: "(UTC-03:00) Montevideo"},
  {value: "America/Bahia", label: "(UTC-03:00) Salvador"},
  {value: "Etc/GMT+2", label: "(UTC-02:00) Coordinated Universal Time-02"},
  {value: "Atlantic/Azores", label: "(UTC-01:00) Azores"},
  {value: "Atlantic/Cape_Verde", label: "(UTC-01:00) Cape Verde Is."},
  {value: "Africa/Casablanca", label: "(UTC) Casablanca"},
  {value: "Etc/GMT", label: "(UTC) Coordinated Universal Time"},
  {value: "Europe/London", label: "(UTC) Dublin, Edinburgh, Lisbon, London"},
  {value: "Atlantic/Reykjavik", label: "(UTC) Monrovia, Reykjavik"},
  {value: "Europe/Berlin", label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
  {value: "Europe/Budapest", label: "(UTC+02:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"},
  {value: "Europe/Paris", label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"},
  {value: "Europe/Warsaw", label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"},
  {value: "Africa/Lagos", label: "(UTC+01:00) West Central Africa"},
  {value: "Africa/Windhoek", label: "(UTC+01:00) Windhoek"},
  {value: "Europe/Istanbul", label: "(UTC+02:00) Athens, Bucharest, Istanbul"},
  {value: "Asia/Beirut", label: "(UTC+02:00) Beirut"},
  {value: "Africa/Cairo", label: "(UTC+02:00) Cairo"},
  {value: "Asia/Damascus", label: "(UTC+02:00) Damascus"},
  {value: "Africa/Johannesburg", label: "(UTC+02:00) Harare, Pretoria"},
  {value: "Europe/Kiev", label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"},
  {value: "Asia/Jerusalem", label: "(UTC+02:00) Jerusalem"},
  {value: "Africa/Tripoli", label: "(UTC+02:00) Tripoli"},
  {value: "Asia/Amman", label: "(UTC+03:00) Amman"},
  {value: "Asia/Baghdad", label: "(UTC+03:00) Baghdad"},
  {value: "Europe/Minsk", label: "(UTC+03:00) Kaliningrad, Minsk"},
  {value: "Asia/Riyadh", label: "(UTC+03:00) Kuwait, Riyadh"},
  {value: "Africa/Nairobi", label: "(UTC+03:00) Nairobi"},
  {value: "Europe/Moscow", label: "(UTC+03:00) Moscow, St. Petersburg, Volgograd"},
  {value: "Europe/Astrakhan", label: "(UTC+04:00) Samara, Ulyanovsk, Saratov"},
  {value: "Asia/Tehran", label: "(UTC+03:30) Tehran"},
  {value: "Asia/Dubai", label: "(UTC+04:00) Abu Dhabi, Muscat"},
  {value: "Asia/Baku", label: "(UTC+04:00) Baku"},
  {value: "Indian/Mahe", label: "(UTC+04:00) Port Louis"},
  {value: "Asia/Tbilisi", label: "(UTC+04:00) Tbilisi"},
  {value: "Asia/Yerevan", label: "(UTC+04:00) Yerevan"},
  {value: "Asia/Kabul", label: "(UTC+04:30) Kabul"},
  {value: "Asia/Tashkent", label: "(UTC+05:00) Ashgabat, Tashkent"},
  {value: "Asia/Karachi", label: "(UTC+05:00) Islamabad, Karachi"},
  {value: "Asia/Kolkata", label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"},
  {value: "Asia/Colombo", label: "(UTC+05:30) Sri Jayewardenepura Kotte"},
  {value: "Asia/Katmandu", label: "(UTC+05:45) Kathmandu"},
  {value: "Asia/Almaty", label: "(UTC+06:00) Astana"},
  {value: "Asia/Dhaka", label: "(UTC+06:00) Dhaka"},
  {value: "Asia/Yekaterinburg", label: "(UTC+06:00) Ekaterinburg"},
  {value: "Asia/Rangoon", label: "(UTC+06:30) Yangon (Rangoon)"},
  {value: "Asia/Bangkok", label: "(UTC+07:00) Bangkok, Hanoi, Jakarta"},
  {value: "Asia/Novosibirsk", label: "(UTC+07:00) Novosibirsk"},
  {value: "Asia/Shanghai", label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"},
  {value: "Asia/Krasnoyarsk", label: "(UTC+08:00) Krasnoyarsk"},
  {value: "Asia/Singapore", label: "(UTC+08:00) Kuala Lumpur, Singapore"},
  {value: "Australia/Perth", label: "(UTC+08:00) Perth"},
  {value: "Asia/Taipei", label: "(UTC+08:00) Taipei"},
  {value: "Asia/Ulaanbaatar", label: "(UTC+08:00) Ulaanbaatar"},
  {value: "Asia/Irkutsk", label: "(UTC+09:00) Irkutsk"},
  {value: "Asia/Tokyo", label: "(UTC+09:00) Osaka, Sapporo, Tokyo"},
  {value: "Asia/Seoul", label: "(UTC+09:00) Seoul"},
  {value: "Australia/Adelaide", label: "(UTC+09:30) Adelaide"},
  {value: "Australia/Darwin", label: "(UTC+09:30) Darwin"},
  {value: "Australia/Brisbane", label: "(UTC+10:00) Brisbane"},
  {value: "Australia/Sydney", label: "(UTC+10:00) Canberra, Melbourne, Sydney"},
  {value: "Pacific/Port_Moresby", label: "(UTC+10:00) Guam, Port Moresby"},
  {value: "Australia/Hobart", label: "(UTC+10:00) Hobart"},
  {value: "Asia/Yakutsk", label: "(UTC+10:00) Yakutsk"},
  {value: "Pacific/Guadalcanal", label: "(UTC+11:00) Solomon Is., New Caledonia"},
  {value: "Asia/Vladivostok", label: "(UTC+11:00) Vladivostok"},
  {value: "Pacific/Auckland", label: "(UTC+12:00) Auckland, Wellington"},
  {value: "Etc/GMT-12", label: "(UTC+12:00) Coordinated Universal Time+12"},
  {value: "Pacific/Fiji", label: "(UTC+12:00) Fiji"},
  {value: "Asia/Magadan", label: "(UTC+12:00) Magadan"},
  {value: "Asia/Kamchatka", label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"},
  {value: "Etc/GMT-13", label: "(UTC+13:00) Nuku'alofa"},
  {value: "Pacific/Apia", label: "(UTC+13:00) Samoa"}
];

export default TIME_ZONES
